@import "site/variables_color";
@import "default/mixins/mixins-master";
@import "basic/admin";

#woocommerce-product-data ul.wc-tabs li.noi_video_options a:before{
    content: "\f493";
}

#wpwrap:after{
    content: '';
    display: table;
    width: 100%;
}

table.dataTable{
    font-size: 14px;
}

#noi_video_data{
    font-size: 12px;
    .inner{
        padding: 9px 18px;
        input[name="noi_video_product"]{
            margin-right: 3px;
            margin-top: -3px;
        }
        ol{
            margin-left: 1.2em;
            margin-top: -13px;
        }
        p{
            padding-left: 0;
            padding-right: 0;
        }
        label{
            float: none;
            margin: 0;
        }
        textarea{
            float: none;
            height: 9.5em;
            width: 50%;
        }
    }
    .formfield{
        margin-bottom: 20px;
        label{
            display: block;
            margin-bottom: 5px;
            width: 100%;
        }
        input{
            float:none!important
        }
    }
}
td,th{
    &.column-starred{
        width: 19px;
    }
    .starred-template,
    .starred-event{
        cursor: pointer;
        &.loading{
            opacity: .5;
        }
    }
}
/* 
ul.wp-submenu li a[href="admin.php?page=clinician-submitted"]{
    border-top: 1px solid #9e9e9e;
    margin-top: 5px!important;
    padding-top: 9px!important; 
} */

.cec-option-wrap{ 
    position: relative;
    &.loading{
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,.7);
            z-index: 5;
        }
    }
}

.acf-postbox{
    .handle-actions{
      .acf-hndle-cog,
      .handle-order-higher,
      .handle-order-lower{
        display: none!important
      }
    }
} 

.shipping-action{
    display: block;
    margin-top: 7px;
}

#change-attendee-shipping{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    text-align: center;
    z-index: 999999;
    .shader{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.7);
        z-index: 5;
    }
    #change--shipping{
        width:100%;
        max-width: 500px;
        min-height: 300px;
        padding: 40px 20px 20px;
        background-color: #f1f1f1;
        box-shadow: 0 3px 6px rgba(0,0,0,0.3);
        margin: 0 auto;
        z-index: 10;
        position: relative;
        &.loading{
            &:before{
                content: '';
                position: absolute;
                top: 0; left: 0;
                width: 100%; height: 100%;
                display: block;
                background-color: rgba(255,255,255,.7);
                z-index: 5;
            }
        }
    }
    .edit--title{
        margin-top: 0; 
        width: 100%;
      }
      .tb-close-icon{
          cursor: pointer;
      }
      form{
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .empty-field{
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            background: url(img/loading.gif) no-repeat center;
            background-size: 30px 30px;
        }
      }
  
      .form-group{
        margin-bottom: 20px;
        width: calc(50% - 10px);
        &.full{
            width: 100%;
        }
      }
  
      label{
        padding-top: 2px;
        font-weight: bold;
        padding-right: 10px;
        box-sizing: border-box;
        margin-bottom: 5px;
        display: block;
      }

      select,
      input[type="text"]{
        width: 100%;
      }
      p{
          margin: 0;
      }
}

table.tribe-attendees{
    .shipping-address{
        display: none;
    }
    &.show-ship{
        th#status{
            width: 250px;
        }
        .shipping-address{
            display: block;
        }
    }
}

.button-link.edit_attendee{
    display: none!important;
}

#video-course-access{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    .shader{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 5;
        background-color: rgba(0,0,0,.8);
    }
    .box{
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        .inner{
            background-color: #fff;
            padding: 30px;
            max-height: 90vh;
            overflow: auto;
            position: relative;
        }
        table{
            border-top: 1px solid #eaeaea;
            border-right: 1px solid #eaeaea;
        }
        th,td{
            padding: 7px;
            text-align: left;
            border-left: 1px solid #eaeaea;
            border-bottom: 1px solid #eaeaea;
        }
    }
    &.loading{
        .box .inner{
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
                background-color: rgba(255,255,255,.7);
                display: block;
            }
        }
    }
}

.noi-qe-utilities{
    display: flex;
    margin-bottom: 10px!important;
    &.last{
        margin-bottom: 30px!important;
    }
    .field-item{
        padding: 0 .5em;
        margin-right: 25px;
    }
    ol{
        margin-left: 1.4em;
    }
    h3{
        margin-bottom: 5px;
        padding: 0;
    }
    .radio-listed{
        label{
            display: inline-block;
            margin-right: 15px;
        }
    }
    .qe_course_id{
        min-width: 250px;
    }
}

.qe-frontend-is-disabled{
    position: relative;
    min-width: 167px;
    &.disabled{
        pointer-events: none;
        &:before{
            content: 'Please publish the course first';
            position: absolute;
            background-color: rgba(246,247,247,.9);
            width: 100%; height: 100%;
            font-size: 11px;
            padding: .5em;
            top: 0;
            left: 0;
            line-height: 6.5em;
        }
    }
}

.erp-badge-box.box-hr,
a[href="admin.php?page=erp-hr"],
.welcome-panel.tribe-report-panel:before{
    display:none!important 
}
.welcome-panel.tribe-report-panel{
    background: none!important;
    .welcome-panel-content{
        min-height: 100px;
    }
}
