// Colours
$dn-text-color: #000;
$dn-bg-color: #ddd;
$dn-bg-color-2: #eee;
$dn-primary-color: #f57f29;
$dn-secondary-color: #955fa5;
$dn-success-color: #45af4a;
$dn-fail-color: #bf1827;
$dn-form-element-color: #7f7f7f;
$orange: #f37f36;
$red: #d90e21;
$purple: #9461a3;