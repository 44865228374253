#wpadminbar #wp-admin-bar-my-sites > .ab-item,
#wpadminbar #wp-admin-bar-site-name > .ab-item {
  padding-left: 40px;
}

#dn_option-contact_maps img {
  max-width: none;
}

#wpadminbar #wp-admin-bar-my-sites > .ab-item:before,
#wpadminbar #wp-admin-bar-site-name > .ab-item:before {
  content: "" !important;
  width: 35px;
  height: 28px;
  background-image: url(./img/static/site-logo.png) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: transparent !important;
  z-index: 10;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 2px;
  left: 2px;
}

#dashboard_right_now li.comment-count {
  display: none !important;
}

#digital_noir_instruction_metabox ul {
  list-style: disc;
  margin-left: 0.5em;
  padding-left: 0.8em;
  line-height: 1.2em;
}

#digital_noir_instruction_metabox .inside h2,
#digital_noir_instruction_metabox .inside h3,
#digital_noir_instruction_metabox .inside h4 {
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0px;
  padding-bottom: 5px;
}

#digital_noir_instruction_metabox .inside h3 {
  font-size: 16px;
}

#digital_noir_instruction_metabox .inside h4 {
  font-size: 14px;
}

#digital_noir_instruction_metabox .inside p {
  margin-top: 0;
}

#digital_noir_instruction_metabox a {
  text-decoration: underline;
}

#adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head,
#adminmenu .wp-menu-arrow,
#adminmenu .wp-menu-arrow div,
#adminmenu li.current a.menu-top,
#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
.folded #adminmenu li.current.menu-top,
.folded #adminmenu li.wp-has-current-submenu {
  background: $dn-primary-color;
}

#adminmenu li.menu-top:hover,
#adminmenu li.opensub > a.menu-top,
#adminmenu li > a.menu-top:focus,
#adminmenu .wp-submenu a:focus,
#adminmenu .wp-submenu a:hover,
#adminmenu a:hover,
#adminmenu li.menu-top > a:focus,
#adminmenu li a:focus div.wp-menu-image:before,
#adminmenu li.opensub div.wp-menu-image:before,
#adminmenu li:hover div.wp-menu-image:before {
  color: $dn-primary-color;
}

// Hides customise menu under appearance (disabled through PHP)
.hide-if-no-customize {
  display: none;
}

body.roles-administrator {
  .hide-if-no-customize {
    display: block;
  }
}

/////////////////////
// ACF Block stuff //
/////////////////////
.acf-fc-popup .preview {
  position: absolute;
  right: 100%;
  margin-right: 0px;
  top: 0;
  background: #383c44;
  min-height: 100%;
  border-radius: 5px;
  align-content: center;
  display: grid;
}

.acf-fc-popup .preview .inner-preview {
  padding: 10px;
}

.acf-fc-popup .preview img {
  display: block;
}

.acf-flexible-content .layout .acf-fc-layout-handle {
  background-color: $dn-primary-color;
  color: #fff !important;
}

.acf-repeater .acf-actions {
  text-align: center;
}

.postbox.acf-postbox h2 {
  padding: 15px 20px !important;
  color: #fff;
  background: $dn-primary-color;
}

// Clean up ACF & Wordpress buttons
.wp-core-ui .button-primary {
  background: $dn-primary-color;
  border-color: darken($dn-primary-color, 5%) darken($dn-primary-color, 10%)
    darken($dn-primary-color, 10%);
  -webkit-box-shadow: 0 1px 0 darken($dn-primary-color, 10%);
  box-shadow: 0 1px 0 darken($dn-primary-color, 10%);
  color: #fff;
  text-decoration: none;
  text-shadow: 0 -1px 1px darken($dn-primary-color, 10%),
    1px 0 1px darken($dn-primary-color, 10%),
    0 1px 1px darken($dn-primary-color, 10%),
    -1px 0 1px darken($dn-primary-color, 10%);

  &.focus,
  &.hover,
  &:focus,
  &:hover {
    background: lighten($dn-primary-color, 3%);
    border-color: darken($dn-primary-color, 10%);
    color: #fff;
  }

  &.active,
  &:active {
    background: darken($dn-primary-color, 5%);
    border-color: darken($dn-primary-color, 10%);
    -webkit-box-shadow: inset 0 2px 0 darken($dn-primary-color, 10%);
    box-shadow: inset 0 2px 0 darken($dn-primary-color, 10%);
    vertical-align: top;
  }

  &.edit-tickets{
    background-color: #129212;
    border-color: transparent!important;
    box-shadow: none;
    text-shadow: none;
  }
}

.acf-repeater .acf-actions {
  text-align: right;
}

.wp-core-ui .button-primary-disabled,
.wp-core-ui .button-primary.disabled,
.wp-core-ui .button-primary:disabled,
.wp-core-ui .button-primary[disabled] {
  color: lighten($dn-primary-color, 12.5%) !important;
  background: lighten($dn-primary-color, 10%) !important;
  border-color: lighten($dn-primary-color, 7.5%) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) !important;
  cursor: default;
  color: white !important;
}

// Gallery back-end styling
.acf-gallery-side-inner .compat-field-gallery_video {
  display: table-row !important;
}

.compat-field-gallery_video {
  display: none !important;
}

/////////////////////
// ACF Focal Point //
/////////////////////

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    //   padding-left: $mfp-popup-padding-left-mobile;
    //   padding-right: $mfp-popup-padding-left-mobile;
  }
}

.acf-focal_point-canvas {
  width: 100%;
  cursor: crosshair;
}

.acf-focal_point-image {
  display: none;
}

.acf-focal_point .has-image {
  display: none;
  position: relative;
}

.acf-focal_point .acf-button-delete {
  position: absolute;
  top: -11px;
  right: -11px;
  z-index: 1;
  cursor: pointer;
}

.acf-focal_point .acf-icon {
  opacity: 0;
  transition: 0.25s ease-in-out;
}

.acf-focal_point .acf-icon:hover {
  color: #d54e21;
}

.acf-focal_point .has-image:hover .acf-icon {
  opacity: 1;
}

.acf-focal_point.active .no-image {
  display: none;
}

.acf-focal_point.active .has-image {
  display: block;
  float: left;
}

// Gve everything the NOI custom font
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

// Hide (disable) RSVP stuff
#rsvp_form_toggle {
  display: none !important;
}

body {
  .tribe-report-page tr,
  .tribe-attendees-page tr {
    // User has checked in
    &.tickets_checked {
      td {
        opacity: 1;
      }

      td.column-course_completed {
        a:not(.dn_tickets_coursecomp) {
          display: none;
        }
      }
    }

    input {
      max-width: 100%;
    }

    // User has not checked in
    &:not(.tickets_checked) {
      td.ticket.column-ticket {
        .row-actions {
          .inline {
            a.tickets_checkin,
            a.tickets_uncheckin {
              display: none !important;
            }
          }
        }
      }

      td.column-course_completed {
        a.dn_tickets_coursecomp {
          display: none;
        }
      }
    }

    // Show and hide status of course completed button
    td.course_completed.column-course_completed {
      div.course_completed {
        span:first-of-type {
          display: inline-block;
        }
        span:last-of-type {
          display: none;
        }
      }
      div:not(.course_completed) {
        span:first-of-type {
          display: none;
        }
        span:last-of-type {
          display: inline-block;
        }
      }
    }

    // Show and hide different cert buttons
    td.send_cert.column-send_cert {
      div:not(.course_completed) {
        a {
          &.dn_tickets_sendcert {
            display: none;
          }
        }
      }

      div.course_completed {
        a {
          &.dn_tickets_sendcert_disabled {
            display: none;
          }
        }
      }
    }
    td[class*="column"] {
      white-space: normal;
    }
  }
}

#generate-pdf-form {
  display: none !important;
}

// Hide various parts
table {
  &#event_cost,
  &#event_url {
    display: none !important;
  }
}

// Hide view button on wp user table in back-end
body.users-php {
  table.wp-list-table {
    tbody#the-list {
      tr {
        td.username.column-username.has-row-actions.column-primary {
          span.view {
            display: none !important;
          }
        }
      }
    }
  }
}

// Hide view button on wp user table in back-end
body.tribe_events_page_tickets-attendees {

  h1{
    button.add_attendee,
    a.export{
      display: none!important;
    }
  }

  table.wp-list-table {
    @media print {
      thead {
        div {
          padding-top: 10px;
          padding-bottom: 10px;
          // margin-bottom: 10px;
          // border-bottom: 1px solid #ddd !important;
        }
      }
      tbody {
        tr {
          td,
          th {
            padding-top: 10px;
            margin-top: 10px;
          }
        }
      }
      tfoot {
        display: none !important;
      }
    }
  }
}

// Admin sorting
// body.wp-admin.post-type-product {

//     ul.subsubsub {

//         li.byorder {
//             display: none !important;
//         }
//     }
// }

// Hide some print columns
@media print {
  body.tribe_events_page_tickets-attendees {
    .wp-list-table.attendees {
      //#course_completed,
      #send_cert {
        display: none;
      }

      tbody {
        tr {
          td {
            //&.course_completed,
            &.send_cert {
              display: none;
            }
          }
        }
      }

      tfoot {
        tr {
          td,
          th {
            //&.column-course_completed,
            &.column-send_cert {
              display: none;
            }
          }
        }
      }
      tfoot tr th.column-check_in,
      thead tr th#check_in,
      tbody tr td.check_in {
        display: table-cell;
      }
      tbody tr td.check_in > * {
        display: none;
      }
      tbody tr.tickets_checked td.check_in:before {
        display: block;
        content: "Yes";
      }
    }
  }
  .hidden-print {
    display: none !important;
  }
  .show-print {
    display: block !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table;
  }

  tr.visible-print {
    display: table-row !important;
  }

  td.visible-print,
  th.visible-print {
    display: table-cell !important;
  }
}
.wp-list-table.attendees {
  thead {
    th {
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      span {
        position: relative;

        @media print {
          display: none !important;
        }
      }
      span::after {
        content: "\f140";
        font: normal 20px/1 dashicons;
        speak: none;
        display: inline-block;
        padding: 0;
        top: -4px;
        left: -8px;
        color: #444;
        line-height: 10px;
        position: relative;
        vertical-align: top;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-decoration: none !important;
        color: #444;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.tablesorter-header.tablesorter-headerDesc {
        span {
          &::after {
            content: "\f142";
          }
        }
      }
    }
  }
}


#add_attendee{
  padding: 0 30px;
  .form-row{
    margin-bottom: 0;
  }
  .spacing-hr{
    margin: 15px 0;
  }
  .fieldset{
    text-align: left;
    margin-bottom: 8px;
    &:after{
      content: '';
      display: table;
      width: 100%;
    }
    > *{
      float: left;
      width: 70%
    }
    label{
      width:30%;
      padding-top: 2px;
      font-weight: bold;
      padding-right: 10px;
      box-sizing: border-box;
    }
    .field-holder {
      p{
        margin-top: 0;
      }
      select,input[type="text"],input[type="number"],textarea{
        width: 100%;
        padding: 5px 10px;
        min-height: 0px;
        line-height: 1.2;
        font-size: 15px;
      }
      label{
        position: relative;
        padding-left:35px;
        font-weight: normal;
        width: 100%;
        box-sizing: border-box;
        display: inline-block;
        input{
          position: absolute;
          top: 8px;
          left: 0;
        }
      }
    }
  }
}
td.state,
#fieldset-state,
#topics-filter,
#upload-results,
.form-holder,
.form-add-holder{
  position: relative;
  &.loading:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.8);
    z-index: 999999;
  }
}

#do-bulk-import-attendee-form{
  background: #fff;
  padding:5px;
  table{
    vertical-align: top;
    tr:nth-of-type(even) td{
      background: #eaeaea;
    }
    td{
      vertical-align: top;
      text-align: left;
    }
    p{
      margin-top: 0;
      margin-bottom: 5px;
      margin-left: 5px;
      margin-right: 5px
    }
    th,td{
      padding: 10px 5px;
      &.ticket{
        width: 7%
      }
      &.paid{
        width: 3%;
        input{
          text-align: right
        }
      }
      &.fname,
      &.lname{
        width: 8%
      }
      &.country{
        width: 7%
      }
      &.action{
        width: 150px
      }
      input[type="text"],
      select{
        width:100%
      }
    }
  }
  .delete-row{
    width: 20px;
    height: 20px;
    text-align: center;
    text-decoration: none;
    line-height: 20px;
    font-weight: bold;
    background: red;
    color: #fff;
    display: inline-block;
    border-radius: 50%;
  }
  .form-action{
    padding: 25px 0;
    input{
      margin-right: 20px
    }
  }
}


.noi-popup-backend{
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;

  .shader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.7);
    z-index: 5;
  }

  .form-close-top{
    cursor: pointer;
  }

  #update-ticket-form{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    padding:40px 20px 20px;
    background-color: #f1f1f1;
    box-shadow: 0 3px 6px rgba( 0, 0, 0, 0.3 );
    margin: 0 auto;
    z-index: 10;

    .edit--title{
      margin-top: 0; 
    }

    .form-group{
      margin-bottom: 20px;
    }

    label{
      padding-top: 2px;
      font-weight: bold;
      padding-right: 10px;
      box-sizing: border-box;
      margin-bottom: 5px;
      display: block;
    }

    input[type="text"]{
      width: 100%;
    }

  }
}

.cert-name-info{
  margin-top: 5px;
}

[data-name="_clinician_state"] .acf-input-wrap{
  position: relative;
  display: block;
  &.loading:before{
    content: '';
    position: absolute;
    top: 0em;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.7);
    z-index: 5;
    max-width: 25em;
  }
}

.noi-update-ticket-progress{
  #update-ticket-form{
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, .7);
    }
  }
}

#modal-send-bulk-attendee{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.7);
  z-index: 5555555;
  display: none;

  .inner{
    width: 600px;
    position: relative;
    background: #fff;
    padding: 30px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .attendee--catch{
    opacity: .5;
  }
  .done{
    background-color: green;
    color: #fff;
    padding: 15px;
    text-align: center;
    margin-top: 15px;
  }
}

.text-input-right{
  input{
    text-align: right;
  }
}

.inline-edit-tribe_events.inline-editor{
    ul.cat-checklist{
        height: 6em;
    }
}
.noi-course-quick-edit{
    margin-bottom: 30px;
    h3{
        margin-bottom: 5px;
        padding: 0 .5em;
    }
    .noi-radio-label{
        display: inline-block;
        margin-right: 15px;
        span{
            display: inline-block;
            vertical-align: middle;
        }
    }
}